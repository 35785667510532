import { useSecureSessionDelete } from '@/composables/dataFetching/genericFetchers';
import { useUserContext } from '@/stores/useUserContext';
import { handleLoadingError } from '~/utils/handleLoadingError';

export default async function useDeleteBankData() {
  try {
    await useSecureSessionDelete(
      `/api/${useSiteIdent()}/user/account/bankData`,
    );
    await useUserContext().loadAccountData(true);
    return true;
  } catch (e) {
    handleLoadingError(e);
    return false;
  }
}

<template>
  <Dialog :is-loading="isLoading">
    <div class="flex flex-col p-md">
      <div>
        {{ t('account.payment.bankData.remove.info') }}
      </div>
      <NotificationBar class="!mt-sm" :type="NotificationType.WARN">
        <div>
          {{ t('account.payment.bankData.remove.warningExisitingOrders') }}
        </div>
      </NotificationBar>

      <div class="mt-sm">
        <i18n-t keypath="account.payment.bankData.remove.checkOrders">
          <template #myInvoices>
            <a
              class="font-bold cursor-pointer text-primary-base"
              @click="redirectTo('/account/orders#invoices')"
            >
              {{ t('shop.yourBills') }}
            </a>
          </template>
        </i18n-t>
      </div>

      <h5 class="mt-sm">
        {{ t('account.payment.bankData.remove.Sepa') }}
      </h5>
      <div class="table pt-sm">
        <div class="table-row">
          <div class="table-cell pr-xs">
            {{ t('checkout.review.payment.sepa.creditor.label') }}:
          </div>
          <div class="table-cell">
            {{ t('checkout.review.payment.sepa.creditor.value') }}
          </div>
        </div>
        <div class="table-row">
          <div class="table-cell pr-xs">
            {{ t('checkout.review.payment.sepa.creditor.id.label') }}:
          </div>
          <div class="table-cell">
            {{ t('checkout.review.payment.sepa.creditor.id.value') }}
          </div>
        </div>
      </div>
      <BasicButton
        full-width
        class="!w-full mt-md"
        :label="t('account.payment.bankData.remove.confirm')"
        @click="handleConfirm"
      />
      <div
        class="mx-auto cursor-pointer text-primary-base px-sm mt-sm"
        @click="deleteDialog.close()"
      >
        {{ t('formFields.form.cancel') }}
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import useDeleteBankData from '~/composables/user/useDeleteBankData';
import useDeleteBankDataDialog from '@/composables/dialogs/useDeleteBankDataDialog';
import { NotificationBar, NotificationType, BasicButton } from '@/complib';
const { t } = useTrans();
const deleteDialog = useDeleteBankDataDialog();
deleteDialog.setHeadline(t('account.payment.bankData.remove.headline'));
const isLoading = ref(false);

async function handleConfirm() {
  isLoading.value = true;
  await useDeleteBankData();
  isLoading.value = false;
  deleteDialog.close();
}

function redirectTo(path: string) {
  window.open(path, '_blank');
}
</script>
